<template>
  <v-container
    id="my-contacts"
    fluid
    tag="section"
  >
    <v-overlay :value="isOverlayVisible">
      <v-progress-circular
        :size="70"
        :width="7"
        color="amber"
        indeterminate
        class="spinner"
      />
    </v-overlay>
    <base-v-component
      heading="Templates"
      sub-heading="Manage Templates"
    />

    <template>
      <v-toolbar
        flat
        color="transparent"
      >
        <v-spacer />
        <v-btn
          color="primary"
          dark
          class="mb-2"
          @click="addNewTemplate"
        >
          <v-icon
            small
            class="mr-2"
          >
            mdi-plus
          </v-icon>
          Add New Template
        </v-btn>
        <template-add-edit-dialog
          :is-visible="isTemplateAddEditDialogVisible"
          :is-edit-mode="isTemplateEditMode"
          :selected-template="selectedTemplate"
          @on-submit="templateModalActionStart"
          @on-close="hideTemplateModal"
          @on-success="templateModalActionSuccess"
          @on-error="templateModalActionError"
        />
      </v-toolbar>
    </template>

    <v-data-table
      :headers="headers"
      :items="allTemplates"
      item-key="cellNumber"
      sort-by="firstName"
    >
      <template v-slot:item.actions="{ item }">
        <div class="senderActions">
          <v-icon
            small
            @click="editTemplate(item)"
          >
            mdi-update
          </v-icon>

          <v-icon
            small
            @click="deleteTemplate(item)"
          >
            mdi-delete
          </v-icon>
        </div>
      </template>
      <template v-slot:no-data>
        <p class="mb-4 mt-4">
          No templates to display
        </p>
        <v-btn
          color="primary"
          outlined
          class="mb-4"
          @click="initialize"
        >
          Refresh
        </v-btn>
      </template>
    </v-data-table>

    <v-snackbar
      v-model="snackbar"
    >
      {{ notification }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <div class="py-3" />
  </v-container>
</template>

<script>
  import HttpConnector from '../../../utils/http-connector'
  import { VueMaskDirective } from 'v-mask'
  import Vue from 'vue'
  Vue.directive('mask', VueMaskDirective)

  export default {
    components: {
      TemplateAddEditDialog: () => import('../components/TemplateAddEditDialog'),
    },
    data: () => ({
      httpConnector: new HttpConnector(),
      apiEndpoint: process.env.VUE_APP_API_BASE_URL,
      apiCode: process.env.VUE_APP_API_CODE,
      dialog: false,
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'Body', value: 'templateBody' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      snackbar: false,
      notification: 'Unknown',
      isOverlayVisible: false,
      allTemplates: [],
      isTemplateAddEditDialogVisible: false,
      isTemplateEditMode: false,
    }),

    async created () {
      this.username = localStorage.getItem('username')
      await this.initialize()
    },

    methods: {
      async initialize () {
        try {
          this.isOverlayVisible = true
          await this.fetchTemplates()
          this.isOverlayVisible = false
          this.close()
        } catch (error) {
          this.isOverlayVisible = false
        }
      },

      addNewTemplate () {
        this.isTemplateEditMode = false
        this.isTemplateAddEditDialogVisible = true
      },

      async fetchTemplates () {
        try {
          const { data } = await this.httpConnector.makeRequest('get', `${this.apiEndpoint}template/list?code=${this.apiCode}`)
          if (data) {
            this.allTemplates = data
          }
        } catch (error) {

        }
      },

      templateModalActionStart () {
        this.isOverlayVisible = true
      },

      async templateModalActionSuccess (res) {
        this.hideTemplateModal()
        await this.initialize()
        this.isOverlayVisible = false
      },

      hideTemplateModal () {
        this.isTemplateAddEditDialogVisible = false
      },

      async deleteTemplate (selectedTemplate) {
        if (confirm('Are you sure you want to delete this template?')) {
          // Delete it
          try {
            this.isOverlayVisible = true
            await this.httpConnector.makeRequest('post', `${this.apiEndpoint}template/delete/${selectedTemplate.templateID}?code=${this.apiCode}`)
            await this.initialize()
            this.isOverlayVisible = false
          } catch (error) {

          }
        }
      },

      editTemplate (selectedTemplate) {
        this.selectedTemplate = selectedTemplate
        this.isTemplateAddEditDialogVisible = true
        this.isTemplateEditMode = true
      },

      templateModalActionError (error) {
        this.isOverlayVisible = false
        this.notification = 'Server error'
        this.snackbar = true
        throw error
      },
    },
  }

</script>
<style scoped>
  .v-overlay.v-overlay--active.theme--dark {
    z-index: 300!important;
  }
  .spinner {
    margin-left: 17rem;
  }
  @media only screen and (max-width: 968px) {
    .spinner {
      margin-left: 0;
    }
  }
</style>
